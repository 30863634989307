exports.components = {
  "component---src-templates-akcja-cytrynowka-closed-page-tsx": () => import("./../../../src/templates/akcja-cytrynowka-closed-page.tsx" /* webpackChunkName: "component---src-templates-akcja-cytrynowka-closed-page-tsx" */),
  "component---src-templates-akcja-cytrynowka-expired-page-tsx": () => import("./../../../src/templates/akcja-cytrynowka-expired-page.tsx" /* webpackChunkName: "component---src-templates-akcja-cytrynowka-expired-page-tsx" */),
  "component---src-templates-akcja-cytrynowka-winner-page-tsx": () => import("./../../../src/templates/akcja-cytrynowka-winner-page.tsx" /* webpackChunkName: "component---src-templates-akcja-cytrynowka-winner-page-tsx" */),
  "component---src-templates-contest-regulations-page-tsx": () => import("./../../../src/templates/contest-regulations-page.tsx" /* webpackChunkName: "component---src-templates-contest-regulations-page-tsx" */),
  "component---src-templates-dessert-page-tsx": () => import("./../../../src/templates/dessert-page.tsx" /* webpackChunkName: "component---src-templates-dessert-page-tsx" */),
  "component---src-templates-flavored-page-tsx": () => import("./../../../src/templates/flavored-page.tsx" /* webpackChunkName: "component---src-templates-flavored-page-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-label-occasions-page-tsx": () => import("./../../../src/templates/label-occasions-page.tsx" /* webpackChunkName: "component---src-templates-label-occasions-page-tsx" */),
  "component---src-templates-lemon-page-tsx": () => import("./../../../src/templates/lemon-page.tsx" /* webpackChunkName: "component---src-templates-lemon-page-tsx" */),
  "component---src-templates-links-page-tsx": () => import("./../../../src/templates/links-page.tsx" /* webpackChunkName: "component---src-templates-links-page-tsx" */),
  "component---src-templates-mulled-page-tsx": () => import("./../../../src/templates/mulled-page.tsx" /* webpackChunkName: "component---src-templates-mulled-page-tsx" */),
  "component---src-templates-old-polish-page-tsx": () => import("./../../../src/templates/old-polish-page.tsx" /* webpackChunkName: "component---src-templates-old-polish-page-tsx" */),
  "component---src-templates-order-labels-page-tsx": () => import("./../../../src/templates/order-labels-page.tsx" /* webpackChunkName: "component---src-templates-order-labels-page-tsx" */),
  "component---src-templates-product-details-page-tsx": () => import("./../../../src/templates/product-details-page.tsx" /* webpackChunkName: "component---src-templates-product-details-page-tsx" */),
  "component---src-templates-product-listing-page-tsx": () => import("./../../../src/templates/product-listing-page.tsx" /* webpackChunkName: "component---src-templates-product-listing-page-tsx" */),
  "component---src-templates-recipe-listing-tsx": () => import("./../../../src/templates/recipe-listing.tsx" /* webpackChunkName: "component---src-templates-recipe-listing-tsx" */),
  "component---src-templates-recipe-page-tsx": () => import("./../../../src/templates/recipe-page.tsx" /* webpackChunkName: "component---src-templates-recipe-page-tsx" */),
  "component---src-templates-soplica-gorzka-tsx": () => import("./../../../src/templates/soplica-gorzka.tsx" /* webpackChunkName: "component---src-templates-soplica-gorzka-tsx" */),
  "component---src-templates-soplica-szlachetna-tsx": () => import("./../../../src/templates/soplica-szlachetna.tsx" /* webpackChunkName: "component---src-templates-soplica-szlachetna-tsx" */),
  "component---src-templates-spirit-page-tsx": () => import("./../../../src/templates/spirit-page.tsx" /* webpackChunkName: "component---src-templates-spirit-page-tsx" */),
  "component---src-templates-summer-contest-closed-tsx": () => import("./../../../src/templates/summer-contest-closed.tsx" /* webpackChunkName: "component---src-templates-summer-contest-closed-tsx" */),
  "component---src-templates-summer-contest-expired-tsx": () => import("./../../../src/templates/summer-contest-expired.tsx" /* webpackChunkName: "component---src-templates-summer-contest-expired-tsx" */),
  "component---src-templates-summer-contest-winner-page-tsx": () => import("./../../../src/templates/summer-contest-winner-page.tsx" /* webpackChunkName: "component---src-templates-summer-contest-winner-page-tsx" */),
  "component---src-templates-tag-listing-tsx": () => import("./../../../src/templates/tag-listing.tsx" /* webpackChunkName: "component---src-templates-tag-listing-tsx" */),
  "component---src-templates-video-listing-tsx": () => import("./../../../src/templates/video-listing.tsx" /* webpackChunkName: "component---src-templates-video-listing-tsx" */),
  "component---src-templates-video-page-tsx": () => import("./../../../src/templates/video-page.tsx" /* webpackChunkName: "component---src-templates-video-page-tsx" */),
  "component---src-templates-vodka-page-tsx": () => import("./../../../src/templates/vodka-page.tsx" /* webpackChunkName: "component---src-templates-vodka-page-tsx" */),
  "component---src-templates-wedding-contest-closed-tsx": () => import("./../../../src/templates/wedding-contest-closed.tsx" /* webpackChunkName: "component---src-templates-wedding-contest-closed-tsx" */),
  "component---src-templates-winter-contest-closed-tsx": () => import("./../../../src/templates/winter-contest-closed.tsx" /* webpackChunkName: "component---src-templates-winter-contest-closed-tsx" */),
  "component---src-templates-winter-contest-expired-tsx": () => import("./../../../src/templates/winter-contest-expired.tsx" /* webpackChunkName: "component---src-templates-winter-contest-expired-tsx" */),
  "component---src-templates-winter-contest-winner-page-tsx": () => import("./../../../src/templates/winter-contest-winner-page.tsx" /* webpackChunkName: "component---src-templates-winter-contest-winner-page-tsx" */),
  "component---src-templates-zabka-contest-closed-page-tsx": () => import("./../../../src/templates/zabka-contest-closed-page.tsx" /* webpackChunkName: "component---src-templates-zabka-contest-closed-page-tsx" */),
  "component---src-templates-zabka-contest-expired-page-tsx": () => import("./../../../src/templates/zabka-contest-expired-page.tsx" /* webpackChunkName: "component---src-templates-zabka-contest-expired-page-tsx" */),
  "component---src-templates-zabka-contest-winner-page-tsx": () => import("./../../../src/templates/zabka-contest-winner-page.tsx" /* webpackChunkName: "component---src-templates-zabka-contest-winner-page-tsx" */)
}

